import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@scuf/common/honeywell-compact-dark/theme.css';
import '@scuf/datatable/honeywell-compact-dark/theme.css';
import store from './stores';
import { Provider } from 'mobx-react';
import './index.scss';
import {routerFn} from "./routerInstance";

const wrappedApp = (
  <Provider {...store}>
    <App />
  </Provider>
);


routerFn().start(() => {
  ReactDOM.render(wrappedApp, document.getElementById('root') as HTMLElement);
});
