import GatewayIdentityWrapper from './components/gateway/GatewayIdentityWrapper';
import DIYGatewayManagementAppWrapper from './pages/GatewayManager/DIYGatewayManagementAppWrapper';
import {FCPDIYGatewayManagementAppWrapper} from "./pages/GatewayManager/FCPDIYGatewayManagementAppWrapper";
import {DIYHomePage} from "./pages/GatewayManager/DIYHomePage";

/*
    - Routes.ts is the master router config file. Each item defines a route by its name and the parameters it accepts.
    - The component bound to the route will be loaded into the base route view component.
*/
export default [
    {
        name: 'gatewayList',
        exact: true,
        path: '/identity',
        component: GatewayIdentityWrapper
    },
    {
        name: 'decision-page',
        exact: true,
        path: '/',
        component: DIYHomePage
    },
    {
        name: 'ap-register',
        exact: true,
        path: '/ap/register?deviceSerialNumber&originalEp&enrollmentId',
        component: DIYGatewayManagementAppWrapper
    },
    {
        name: 'fcp-register',
        path: "/fcp/register/:action?deviceSerialNumber&originalEp&enrollmentId",
        component: FCPDIYGatewayManagementAppWrapper
    }
];