/**
 * Copyright 2020 Tridium, Inc. All Rights Reserved.
 */

import IEnvVariables from "../GlobalStore/GlobalStore";
import * as AuthenticationContext from "adal-angular";

export type CacheLocation = 'localStorage' | 'sessionStorage' | undefined;

/**
 * The configuration for ADAL (Active directory Openid connection)
 */
export class ADOpenIdConfig {
  /**
   * Get the applicable openid/oauth config
   */
  getInstance(envVariables: IEnvVariables): AuthenticationContext.Options {
    const endpoints: { [resource: string]: string } = {};
    endpoints['api'] = envVariables.AD_AUDIENCE;
    //if feature flag enabled get DIY App Client Id else get Admin Portal Client ID
    let clientId =  envVariables.GATEWAY_SETUP_APP_CLIENT_ID;
    return {
      /**
       * The Active directory Open ID instance
       */
      instance: envVariables.AD_INSTANCE,

      /**
       * The client application (Client Id is based on the feature flag)
       * 
       */
      clientId: clientId,

      /**
       *  The endpoints and their audience resources
       */
      endpoints: endpoints,

      // // 'tenant' is the Azure AD instance.
      tenant: envVariables.AD_TENANT,

      // // 'cacheLocation' is set to 'sessionStorage' by default, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options
      // // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
      cacheLocation: 'localStorage' as CacheLocation,

      /**
       * The Redirect URI for the id token
       */
      redirectUri: window.location.origin + "/ap/register",

      /**
       *  For legacy mobile UX
       */
      extraQueryParameter: 'nux=1'
    }
  }

}


export default new ADOpenIdConfig();
