import GlobalStore from './stores/GlobalStore/GlobalStore';
import SiteSelectionStore from "./stores/GatewayManager/SiteSelectionStore";
import GatewayIdentityStore from "./stores/GatewayManager/GatewayIdentityStore";
import GatewayRegistrationStore
  from "./stores/GatewayManager/GatewayRegistrationStore";
import GatewayValidationStore
  from "./stores/GatewayManager/GatewayValidationStore";
import { GlobalRouterStore } from './stores/RouterStore';
import GatewayRegistration from './components/service/gatewayRegistration/GatewayRegistration'

/* 
    - This is where the stores are instantiated and given their dependencies.
    - The keys here are referenced in each component's inject statements.
    - Remember that every new instance of a store class will have unique states, so it is best to instantiate them once at this level.
*/
export default {
  routerStore: new GlobalRouterStore(),
  globalStore: new GlobalStore(),
  siteSelectionStore: new SiteSelectionStore(),
  gatewayValidationStore: new GatewayValidationStore(),
  gatewayRegistrationStore: new GatewayRegistrationStore(),
  gatewayIdentityStore: new GatewayIdentityStore(),
  gatewayRegistration: new GatewayRegistration()
};
