
// Here the router is bootstrapped
import createRouter from "./router";


export const routerFn = () => {
    if (window.router == null) {
        window.router = createRouter();
    }
    return window.router;
}