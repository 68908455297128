import { observable } from 'mobx';
import { Router } from "router5/create-router";

export default interface IEnvVariables {
  PORTAL_API_GATEWAY_URL: string;
  CLOUDCONNECTOR_API_BASE_URL: string;
  OCP_APIM_SUBSCRIPTION_KEY: string;
  OCP_APIM_SUBSCRIPTION_KEY_CLOUDCONNECTOR: string;
  GATEWAY_MANAGEMENT_BASE_API_URL: string;
  FORGE_CONNECT_SERVICE_BASE_API_URL: string;
  AD_INSTANCE: string;
  GATEWAY_SETUP_APP_CLIENT_ID: string;
  AD_AUDIENCE: string;
  AD_TENANT: string;
  ADMIN_PORTAL_LAUNCHDARKLY_CLIENT_ID: string;
  FC_PORTAL_LAUNCHDARKLY_CLIENT_ID: string;
  SUPPORT_EMAIL_ID: string;
  SUPPORT_PHONE_NUMBERS: string;
  ALLOW_PRE_REGISTERED_SNAPS: string;
  MAX_CUSTOMER_DETAILS_SUPPORTED: string;
  FC_KONG_API_KEY: string;
  SIMPLYCONNECT_API_URL: string;
  SIMPLYCONNECT_SYSTEM_ID: string;
  FORGE_IAM_AUTHORITY: string;
  FC_CLIENT_ID: string;
  FC_IAM_REQUESTED_SCOPES: string;
  AP_ONBOARDING_FROM_EMAIL: string;
  FP_ONBOARDING_FROM_EMAIL: string;
  FCP_SUPPORT_EMAIL_ID: string;
  FCP_SUPPORT_PHONE_NUMBERS: string;
  FCP_API_BASE_URL:string;
  FCP_KONG_API_KEY:string;
  CAAS_FCS_BASE_API_URL:string;
}

declare global {
  interface Window {
    envVariables: IEnvVariables;
    router: Router
  }
}

/**
 * The base store
 */
export default class GlobalStore {
  @observable
  public envVariables = window.envVariables;
}
